import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
} from '@mui/material';



const TrasladoBodegaCocina = () => { 
  
const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);  
  const [searchValue, setSearchValue] = useState('');
  const [transferQuantity, setTransferQuantity] = useState(0);
  const [availableItemsTSCR, setAvailableItemsTSCR] = useState([]);
  const [availableItemsCCO, setAvailableItemsCCO] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false); 
  const [bodegaSeleccionada, setBodegaSeleccionada] = useState('');
  const [comentario, setComentario] = useState('');


  const tiendasPrecargadas = [
    { id: 1, nombre: 'TZ10' },
    { id: 2, nombre: 'TPRR' },
    { id: 3, nombre: 'TSCR' },
    { id: 4, nombre: 'TCSV' },
    { id: 5, nombre: 'TMIR' },
    { id: 6, nombre: 'TXLA' }
  ];


  // Función para obtener datos de productos por bodega
  const fetchData = (bodega, setFunction) => {
    const datos = {
      Token:
        'LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY',
      Ambiente: 'DESA',
      Dispositivo: {
        Marca: 'SAMSUNG',
        Modelo: 'S22002B',
        Version: 'Android 18.125',
        McAdress: null,
        PowerStatus: '84%',
        WifiStatus: 'Conected to iphone de Mauricio',
        GPSStatus: 'ON',
        GPS: '321|-485|321',
      },
      Usr: {
        Codigo: '',
        UserNickName: 'pierril',
      },
      DatosMetodo: {
        NombreMetodo: 'ListaProductosExistenciaSalas',
        Datos_Recibidos: `{ "BODEGA": "${bodega}" }`,
      },
    };

    const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(
      JSON.stringify(datos)
    )}`;

    fetch(fetchDataUrl)
      .then((response) => response.json())
      .then((data) => {
        try {
          const decodedString = JSON.parse(data.datos_Generados);
          const stockData = decodedString.STOCK.map((item) => ({
            codigo: item['Código del Producto'],
            descripcion: item['Nombre del Producto'],
            cantidadDisponible: item['Stock Disponible'],
          }));
          setFunction(stockData);
        } catch (error) {
          console.error('Error al procesar datos_Generados:', error);
        }
      })
      .catch((error) => console.error('Error en la solicitud:', error));
  }; 
  const getBodegasFiltradas = () => {
    if (tiendasPrecargadas.some(tienda => tienda.nombre === user.usuario)) {
      // Si coincide con una tienda, excluirla de la lista
      return tiendasPrecargadas.filter(tienda => tienda.nombre !== user.usuario);
    }
    // Si no coincide, mostrar todas las bodegas
    return tiendasPrecargadas;
  };
  

  // Obtener datos al montar el componente
  useEffect(() => {
    if (bodegaSeleccionada) {
      fetchData(bodegaSeleccionada, setAvailableItemsTSCR);
      fetchData(`${bodegaSeleccionada}-CCO`, setAvailableItemsCCO);
    }
  }, [bodegaSeleccionada]);
  
  useEffect(() => {
    const bodegaInicial = tiendasPrecargadas.find(tienda => tienda.nombre === user.usuario);
    if (bodegaInicial) {
      setBodegaSeleccionada(bodegaInicial.nombre);
    }
  }, [user]);
  
  const usuarioEnTiendasPrecargadas = tiendasPrecargadas.some(
    (tienda) => tienda.nombre === user.usuario
  );
  // Agregar producto a la lista seleccionada
  const handleAdd = () => {
    const itemToAdd = availableItemsTSCR.find(
      (item) => item.codigo === searchValue
    );
    if (
      itemToAdd &&
      transferQuantity > 0 &&
      transferQuantity <= itemToAdd.cantidadDisponible
    ) {
      setSelectedItems([
        ...selectedItems,
        { ...itemToAdd, cantidadTransferida: transferQuantity },
      ]);
      setAvailableItemsTSCR(
        availableItemsTSCR.map((item) =>
          item.codigo === searchValue
            ? {
                ...item,
                cantidadDisponible: item.cantidadDisponible - transferQuantity,
              }
            : item
        )
      );
      setSearchValue('');
      setTransferQuantity(0);
    }
  };
  // remove  
  const handleRemove = (codigo) => {
    // Encuentra el elemento a remover en selectedItems
    const itemToRemove = selectedItems.find((item) => item.codigo === codigo);
  
    if (itemToRemove) {
      // Actualiza los items disponibles en TSCR, devolviendo la cantidad transferida
      setAvailableItemsTSCR((prevAvailableItems) =>
        prevAvailableItems.map((item) =>
          item.codigo === codigo
            ? {
                ...item,
                cantidadDisponible: item.cantidadDisponible + itemToRemove.cantidadTransferida,
              }
            : item
        )
      );
  
      // Filtra la lista de seleccionados para eliminar el ítem
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item.codigo !== codigo)
      );
    }
  };
  


  // Enviar productos seleccionados
  const handleSend = () => {
    setIsLoading(true); // Activar loading
    const productos = selectedItems.map((item) => ({
      ItemCode: item.codigo,
      Quantity: item.cantidadTransferida,
    }));

    const datos = {
      Token:
        'LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY',
      Ambiente: 'DESA',
      Dispositivo: {
        Marca: 'SAMSUNG',
        Modelo: 'S22002B',
        Version: 'Android 18.125',
        McAdress: null,
        PowerStatus: '84%',
        WifiStatus: 'Conected to iphone de Mauricio',
        GPSStatus: 'ON',
        GPS: '321|-485|321',
      },
      User: {
        Codigo: '',
        UserNickName: 'pierril',
      },
      Metodo: {
        NombreMetodo: 'StockTransfer',
        Datos_Recibidos: JSON.stringify({
          Series: null,
          BodegaOrigen: bodegaSeleccionada, 
          BodegaDestino: bodegaSeleccionada +'-CCO',
          Comentarios: comentario,
          Productos: productos,
        }),
        Datos_Generados: '',
      },
    };

    console.log(datos);
    fetch('https://appcorp.parma.com.gt/API/ReceptorDatos', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(datos),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Respuesta de la API:', data);
        alert('Transferencia enviada con éxito.' + data.datos_Generados);
        setSelectedItems([]); // Limpiar los seleccionados después de enviar
        window.location.reload(); // Recargar la página
      })
      .catch((error) => console.error('Error al enviar transferencia:', error))
      .finally(() => setIsLoading(false)); // Desactivar loading
  };
  

  return ( 
    
    <div style={{ padding: '1rem' }}>
      {/* Div contenedor para Buscar y Agregar */} 
      <div style={{ padding: '1rem' }}>
    {/* Solo mostrar si el usuario NO está en tiendasPrecargadas */}
    {!usuarioEnTiendasPrecargadas && (
      <div style={{ marginBottom: '1rem' }}>
        <h3>Seleccionar Bodega</h3>
        <TextField
          select
          label="Bodega"
          value={bodegaSeleccionada}
          onChange={(e) => setBodegaSeleccionada(e.target.value)}
          style={{ width: '300px' }}
        >
          {/* Aquí irían las opciones */}
		    {getBodegasFiltradas().map((bodega) => (
      <MenuItem key={bodega.id} value={bodega.nombre}>
        {bodega.nombre}
      </MenuItem>
    ))}
        </TextField>
      </div>
    )}
  </div>

      <div style={{ marginBottom: '1rem' }}>
        <h3>Búsqueda y Agregar</h3>
        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          <TextField
            select
            label="Buscar producto"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            style={{ width: '600px' }}
          >
            {availableItemsTSCR.map((item) => (
              <MenuItem key={item.codigo} value={item.codigo}>
                {item.descripcion}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            type="number"
            label="Cantidad a transferir"
            value={transferQuantity}
            onChange={(e) => setTransferQuantity(Number(e.target.value))}
            style={{ width: '150px' }}
          /> 
           <TextField
        label="Escribe un comentario"
        value={comentario}
        onChange={(e) => setComentario(e.target.value)}
        
        style={{ width: '250px' }}
      />
          <Button variant="contained" color="primary" onClick={handleAdd}>
            Agregar
          </Button>
        </div>
      </div>
  
      {/* Contenedor para Tablas Paralelas */}
      <div style={{ display: 'flex', gap: '2rem', marginBottom: '1rem' }}>
        {/* Tabla TSCR */}
        <div style={{ flex: 1, maxHeight: '600px', overflowY: 'auto', border: '1px solid #ccc', padding: '1rem' }}>
          <h3>Disponibles en Tienda</h3>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Codigo</TableCell>
                <TableCell>Descripcion</TableCell> 
                <TableCell>Cantidad</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {availableItemsTSCR.map((item) => (
                <TableRow key={item.codigo}>
                  <TableCell>{item.codigo}</TableCell>
                  <TableCell>{item.descripcion}</TableCell>
                  <TableCell>{item.cantidadDisponible}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
  
        {/* Tabla Productos Agregados */}
        <div style={{ flex: 1, maxHeight: '600px', overflowY: 'auto', border: '1px solid #ccc', padding: '1rem' }}>
          <h3>Productos Agregados</h3>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Código</TableCell>
                <TableCell>Descripción</TableCell>
                <TableCell>Cantidad Transferida</TableCell>
                <TableCell>Acción</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedItems.map((item) => (
                <TableRow key={item.codigo}>
                  <TableCell>{item.codigo}</TableCell>
                  <TableCell>{item.descripcion}</TableCell>
                  <TableCell>{item.cantidadTransferida}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleRemove(item.codigo)}
                    >
                      Quitar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
        variant="contained"
        color="success"
        onClick={handleSend}
        disabled={isLoading} // Deshabilitar botón mientras carga
        style={{ marginTop: '1rem' }}
        startIcon={isLoading && <CircularProgress size={20} color="inherit" />} // Spinner en el botón
      >
        {isLoading ? 'Enviando...' : 'Enviar Transferencia'}
      </Button>
        </div>
      </div>
  
      {/* Tabla CCO */}
      <div style={{ maxHeight: '300px', overflowY: 'auto', border: '1px solid #ccc', padding: '1rem' }}>
        <h3>Disponibles en CCO</h3>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Código</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Cantidad Disponible</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {availableItemsCCO.map((item) => (
              <TableRow key={item.codigo}>
                <TableCell>{item.codigo}</TableCell>
                <TableCell>{item.descripcion}</TableCell>
                <TableCell>{item.cantidadDisponible}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
  
  
};

export default TrasladoBodegaCocina;
