import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField, Button, Table, TableHead, TableBody, TableCell, TableRow, Pagination, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import { faEdit, faDownload, faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';


function Abono() {
  const [datosGenerados, setDatosGenerados] = useState([]);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [filtroTexto, setFiltroTexto] = useState("");
  const [filtroEstado, setFiltroEstado] = useState(""); // Nuevo estado para el filtro de estado
  const [paginaActual, setPaginaActual] = useState(1);
  const [elementosPorPagina] = useState(10);
  const [show, setShow] = useState(false);
  const [resultado, setResultado] = useState(null);
  const [error, setError] = useState(null);
  const [codigoSeleccionado, setCodigoSeleccionado] = useState(null);
  const handleClose = () => setShow(false);
  const { token, user } = useSelector(state => state.auth);

  const [showModal, setShowModal] = useState(false);
  const [comentario, setComentario] = useState(''); 
  const [showModal2, setShowModal2] = useState(false);

  const handleShow = (codigo) => {
    setCodigoSeleccionado(codigo);
    setShow(true);
  };


  const handleAccept = async () => {
    console.log('Código:', codigoSeleccionado);
    const idusuarioenvia = user.id_usuario

    const token = "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY";
    const codigoUsuario = "2186";
    const userNickName = "pierril";
    const metodo = "ValidarAbono";
    const datosRecibidos = [{ acov_tipo: "1", acov_codigo: codigoSeleccionado, acov_usuario: idusuarioenvia }];

    const data = {
      Token: token,
      User: {
        Codigo: codigoUsuario,
        UserNickName: userNickName
      },
      Metodo: {
        NombreMetodo: metodo,
        Datos_Recibidos: JSON.stringify(datosRecibidos),
        Datos_Generados: ""
      }
    };

    try {
      const response = await fetch('https://appcorp.parma.com.gt/API/ReceptorDatos', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)


      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const resultadoAPI = await response.json();
      setResultado(resultadoAPI);
      console.log('Respuesta de la API:', resultadoAPI);
    } catch (error) {
      console.error('Error en la petición:', error);
      setError(error.message);
    }

    handleClose();
  };


  const handlecancelAbono = async () => {
    console.log('Código:', codigoSeleccionado);
    const idusuarioenvia = user.id_usuario

    const token = "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY";
    const codigoUsuario = "2186";
    const userNickName = "pierril";
    const metodo = "ValidarAbono";
    const datosRecibidos = [{ acov_tipo: "2", acov_codigo: codigoSeleccionado, acov_usuario: idusuarioenvia, acov_motivo: comentario }];

    const data = {
      Token: token,
      User: {
        Codigo: codigoUsuario,
        UserNickName: userNickName
      },
      Metodo: {
        NombreMetodo: metodo,
        Datos_Recibidos: JSON.stringify(datosRecibidos),
        Datos_Generados: ""
      }
    };


    console.log(data)
    try {
      const response = await fetch('https://appcorp.parma.com.gt/API/ReceptorDatos', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)


      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const resultadoAPI = await response.json();
      setResultado(resultadoAPI);
      console.log('Respuesta de la API:', resultadoAPI);
    } catch (error) {
      console.error('Error en la petición:', error);
      setError(error.message);
    }

    handleClose();
  };


  const handleTrashedAbono = async () => {
    console.log('Código:', codigoSeleccionado);
    const idusuarioenvia = user.id_usuario

    const token = "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY";
    const codigoUsuario = "2186";
    const userNickName = "pierril";
    const metodo = "ValidarAbono";
    const datosRecibidos = [{ acov_tipo: "3", acov_codigo: codigoSeleccionado, acov_usuario: idusuarioenvia, acov_motivo: comentario }];

    const data = {
      Token: token,
      User: {
        Codigo: codigoUsuario,
        UserNickName: userNickName
      },
      Metodo: {
        NombreMetodo: metodo,
        Datos_Recibidos: JSON.stringify(datosRecibidos),
        Datos_Generados: ""
      }
    };


    console.log(data)
    try {
      const response = await fetch('https://appcorp.parma.com.gt/API/ReceptorDatos', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)


      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const resultadoAPI = await response.json();
      setResultado(resultadoAPI);
      console.log('Respuesta de la API:', resultadoAPI);
    } catch (error) {
      console.error('Error en la petición:', error);
      setError(error.message);
    }

    handleClose();
  };
  const fetchData = () => {
    const idusuarioenvia = String(user.id_usuario);
    const nameuser = user.usuario;
    console.log(nameuser);
    const datos = {
      Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
      Ambiente: "DESA",
      Usr: {
        Codigo: idusuarioenvia,
        UserNickName: nameuser
      },
      DatosMetodo: {
        NombreMetodo: "ListaAbonos",
        Datos_Recibidos: `{"FechaIni":"${fechaInicio}","FechaFin":"${fechaFin}"}`
      }
    };

    const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`;

    fetch(fetchDataUrl)
      .then(response => response.json())
      .then(data => {
        if (data && data.datos_Generados) {
          console.log(data)
          const datosGeneradosDecodificados = JSON.parse(data.datos_Generados);
          if (datosGeneradosDecodificados && datosGeneradosDecodificados.LISTABONO) {
            setDatosGenerados(datosGeneradosDecodificados.LISTABONO);
          } else {
            setDatosGenerados([]);
          }
        } else {
          setDatosGenerados([]);
        }
      })
      .catch(error => console.error('Error:', error));
  };

  useEffect(() => {
    fetchData();
  }, [fechaInicio, fechaFin]);

  const handleSearch = () => {
    fetchData();
  };

  const handlePageChange = (event, value) => {
    setPaginaActual(value);
  };

  const filteredData = datosGenerados.filter(item => {
    const searchValue = filtroTexto.toLowerCase();
    const estadoMatch = filtroEstado ? item.Proceso === filtroEstado : true; // Aplica filtro de estado
    return (
      estadoMatch &&
      (
        item.OV.toLowerCase().includes(searchValue) ||
        item.Banco.toLowerCase().includes(searchValue) ||
        item.FormaPago.toLowerCase().includes(searchValue) ||
        item.CodigoCliente.toLowerCase().includes(searchValue) ||
        item.NombreCliente.toLowerCase().includes(searchValue)
      )
    );
  });

  const indexUltimoElemento = paginaActual * elementosPorPagina;
  const indexPrimerElemento = indexUltimoElemento - elementosPorPagina;
  const datosPaginados = filteredData.slice(indexPrimerElemento, indexUltimoElemento);

  const descargarImagen = (base64, extension, nombreArchivo) => {

    console.log(base64)
    const imagenBase64Completa = `data:image/${extension};base64,${base64}`;
    const enlace = document.createElement('a');
    enlace.href = imagenBase64Completa;
    enlace.download = `${nombreArchivo}.${extension}`;
    enlace.click();
  };

  const handlePasarAbono = (codigo) => {
    // Aquí va la lógica para "pasar" el abono
    console.log('Abono aprobado');

    alert('prueba de  validado');
    // Puedes agregar más lógica, como actualizar el estado o hacer una llamada a la API. 
    setCodigoSeleccionado(codigo);
    setShow(true);
  };



  const handleCancelarAbono = (codigo) => {

    setCodigoSeleccionado(codigo);
    alert('El Modal :(')
    setShowModal(true); // Muestra el modal cuando se hace clic en cancelar
  };

  // Manejador para cerrar el modal sin hacer nada
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseModal2 = () => {
    setShowModal2(false);
  };
  // Manejador para confirmar la cancelación
  const handleConfirmarCancelacion = () => {
    console.log('Abono cancelado');
    console.log('Comentario:', comentario); // Aquí puedes manejar el comentario

    // Aquí va la lógica para "cancelar" el abono, por ejemplo, actualizar el estado o hacer una llamada API
    alert('Abono cancelado con comentario: ' + comentario);

    setShowModal(false); // Cierra el modal después de confirmar
  }; 
  const handleTrashAbono = (codigo) => {

    setCodigoSeleccionado(codigo);
    alert('El Modal6666 :(')
    setShowModal2(true); // Muestra el modal cuando se hace clic en cancelar
  };
  

  return (
    <div>
      <h1>Validar Abono</h1>
      <div style={{ marginBottom: '20px', justifyContent: 'space-between' }}>
        <TextField
          id="filtroTexto"
          label="Buscar"
          value={filtroTexto}
          onChange={(e) => setFiltroTexto(e.target.value)}
          variant="outlined"
          style={{ marginBottom: '10px', width: '500px' }}
        />
        <TextField
          id="fechaInicio"
          label="Fecha de Inicio"
          type="date"
          value={fechaInicio}
          onChange={(e) => setFechaInicio(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          style={{ marginRight: '10px' }}
        />
        <TextField
          id="fechaFin"
          label="Fecha de Fin"
          type="date"
          value={fechaFin}
          onChange={(e) => setFechaFin(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          style={{ marginRight: '10px' }}
        />
        <FormControl variant="outlined" style={{ width: '200px', marginRight: '10px' }}>
          <InputLabel>Estado</InputLabel>
          <Select
            value={filtroEstado}
            onChange={(e) => setFiltroEstado(e.target.value)}
            label="Estado"
          >
            <MenuItem value="Pendiente de Validar">Pendiente de Validar</MenuItem>
            <MenuItem value="Validado">Validado</MenuItem>
            <MenuItem value="">Todos</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" onClick={handleSearch} style={{ height: '55px', width: '100px' }}>
          Buscar
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Código Abono</TableCell>
            <TableCell>Tipo de Abono</TableCell>
            <TableCell>Banco</TableCell>
            <TableCell>Forma de Pago</TableCell>
            <TableCell>Orden de Venta</TableCell>
            <TableCell>Código Cliente</TableCell>
            <TableCell>Nombre Clienta</TableCell>
            <TableCell>Número de Referencia</TableCell>
            <TableCell>Monto</TableCell>
            <TableCell>Fecha</TableCell>
            <TableCell>Proceso</TableCell>
            <TableCell>Validar</TableCell>
            <TableCell>Descargar Imagen</TableCell>
            <TableCell>Usuario Validador</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datosPaginados.map(item => (
            <TableRow key={item.acov_codigo}>
              <TableCell>{item.acov_codigo}</TableCell>
              <TableCell>{item.TipoAbono}</TableCell>
              <TableCell>{item.Banco}</TableCell>
              <TableCell>{item.FormaPago}</TableCell>
              <TableCell>{item.OV}</TableCell>
              <TableCell>{item.CodigoCliente}</TableCell>
              <TableCell>{item.NombreCliente}</TableCell>
              <TableCell>{item.NoReferencia}</TableCell>
              <TableCell>{item.Monto}</TableCell>
              <TableCell>{item.Fecha}</TableCell>
              <TableCell>{item.Proceso}</TableCell>
             
              <TableCell>
                { /*<Button
                      onClick={() => handleShow(item.acov_codigo)}
                      color="primary"
                      startIcon={<FontAwesomeIcon icon={faEdit} />}
                      disabled={item.Proceso === "Validado"} // Aquí se coloca el disabled en el Button
                    >
                      Validar
                    </Button> */}


                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    color: 'green',
                    marginRight: '10px',
                    fontSize: '20px',
                    pointerEvents: item.Proceso === "Validado" ? 'none' : 'auto'
                  }}
                  onClick={() => handlePasarAbono(item.acov_codigo)}
                />

                {/* Ícono de Cancelado con evento onClick */}
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{ color: 'red', fontSize: '20px' }}
                  onClick={() => handleCancelarAbono(item.acov_codigo)} // Muestra el modal cuando se hace clic en el ícono
                />

                <FontAwesomeIcon
                  icon={faTrash} // Cambié faTimes por faTrash
                  style={{ color: 'black', fontSize: '20px' }}
                  onClick={() => handleTrashAbono(item.acov_codigo)} // Muestra el modal cuando se hace clic en el ícono
                />

              </TableCell>
              <TableCell>
                <Button
                  onClick={() => descargarImagen(item.B64Imagen, item.B64Extension, `imagen_abono_${item.acov_codigo}`)}
                  color="secondary"
                  startIcon={<FontAwesomeIcon icon={faDownload} />}
                >

                </Button>
              </TableCell> 
              <TableCell>{item.UsuarioValidador}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={Math.ceil(filteredData.length / elementosPorPagina)}
        page={paginaActual}
        onChange={handlePageChange}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación de Validación</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro de que desea validar el abono {codigoSeleccionado}?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleAccept}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>



      {/* Modal para ingresar el comentario */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Cancelar Abono</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Estás seguro de que quieres cancelar este abono?</p>
          <TextField
            label="Comentario"
            value={comentario}
            onChange={(e) => setComentario(e.target.value)}
            fullWidth
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handlecancelAbono}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal> 

      {/* Modal para ingresar el comentario */}
      <Modal show={showModal2} onHide={handleCloseModal2}>
        <Modal.Header closeButton>
          <Modal.Title>Cancelar Abono</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Estás seguro de que quieres cancelar este abono?</p>
          <TextField
            label="Comentario"
            value={comentario}
            onChange={(e) => setComentario(e.target.value)}
            fullWidth
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal2}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleTrashedAbono}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
 
 





    </div>
  );
}

export default Abono;
